<template>
    <div class="my-4">
        <div class="text-center w-100">
            <h1 class="h3">
                Categorías en total
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="col-12 my-3">
                <common-filters
                    v-model="query"
                    :enabled-filters="$data.$enabledFilters"
                    :default-filters="$data.$defaultFilters"
                    @enter="reloadItems"
                />
                <div class="d-flex justify-content-center">
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12 my-3"
            >
                <div class="d-flex align-items-center justify-content-around">
                    <tooltip-button
                        v-show="selected.length"
                        icon="🧹"
                        message="Limpiar selección"
                        @click="clearSelected"
                    />
                    <tooltip-button
                        v-show="
                            selected.length &&
                            $ability.hasScope('category:Create')
                        "
                        icon="©"
                        message="Clonar categoría"
                        @click="cloneSelected"
                    />
                </div>

                <div class="d-flex align-items-center justify-content-around">
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="$ability.hasScope('category:Create')"
                        to="/app/categories/new"
                        message="Nueva categoría"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="single"
                selectable
                responsive
                outlined
                no-border-collapse
                hover
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(slug)="data">
                    <router-link
                        :to="`/app/categories/detail/${data.item.slug}`"
                        class="text-center font-weight-normal btn btn-primary p-1"
                    >
                        <div class="d-flex align-items-center">
                            <small>
                                {{ data.item.active ? "🟢" : "⚪" }}
                                {{ data.item.slug }}
                            </small>
                        </div>
                    </router-link>
                </template>

                <template #cell(name)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.name }}
                    </small>
                </template>
                <template #cell(shortDescription)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.shortDescription }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import CommonFilters from "@/components/utils/CommonFilters.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "CategoriesIndex",
    components: { CommonFilters, TooltipRedirect, TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        query: {},
        keySearch: "",
        selected: [],
        itemsCurrent: [],
        $fields: [
            { key: "slug", label: "Slug", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            {
                key: "shortDescription",
                label: "Descripción",
                sortable: false
            },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        $enabledFilters: ["keyword", "active"],
        $defaultFilters: ["keyword"],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("category:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("categories", ["listCategoriesPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } =
                    await this.listCategoriesPaginated({
                        ...this.query,
                        page: this.currentPage,
                        complete
                    });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        },
        cloneSelected() {
            window
                .open(`/app/categories/clone/${this.selected[0].id}`, "_blank")
                .focus();
        }
    }
};
</script>
